<template>
  <div class="home">
    <MyBanner />
    <MyCompany />
  </div>
</template>

<script>
import { useMeta } from 'vue-meta'
import MyBanner from '@/components/home/Banner'
import MyCompany from '@/components/home/Company'

export default {
  name: 'HomeView',
  setup () {
    useMeta({ title: 'Home' })
  },
  components: {
    MyBanner,
    MyCompany
  },
}
</script>


<style>

  
</style>