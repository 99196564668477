<template>
    <div class="header">
        <div class="b-bg-header"> 
            <div class="container padding0">   
                <header class="col-md-12">
                    <div class="row"> 
                        <div class="col-md-2 col-lg-2 col-sm-2 col-xs-6 padding-l-0 clearfix">
                            <a href="/" class="logo pull-left" >SONCO worldwide</a>  
                        </div>
                        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-6 b-navbar-top-first padding0">
                            <nav class="navbar b-navbar-main-parent" role="navigation">
                                <div id="main_nav" class="navbar-collapse b-navbar-main">
                                    <div class="menu-header-menu-container">
                                        <ul id="menu-header-menu" class="b-navbar-main__ul">
                                            <!-- <li id="menu-item-31" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-31"><a href="#"><span>Our</span> divisions</a>
                                                <ul class="sub-menu">
                                                    <li id="menu-item-489" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-489"><a target="_blank" href="https://hartwellcompanies.com/">Hartwell Companies</a></li>
                                                    <li id="menu-item-488" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-488"><a target="_blank" href="https://soncocrowdcontrol.com/">Perimeter Security</a></li>
                                                    <li id="menu-item-487" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-487"><a target="_blank" href="https://soncotube.com/">Pipe &amp; Tube</a></li>
                                                </ul>
                                            </li> -->
                                            <!-- <li id="menu-item-481" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-481"><a href="/about-us">About Us</a></li> -->
                                            <!-- <li id="menu-item-492" class="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-492"><a href="/careers">Careers</a></li> -->
                                            <!-- <li id="menu-item-490" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-490"><a href="/contact-us">Contact Us</a></li> -->
                                            <!-- <li id="menu-item-491" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-491"><a>1-888-766-2618</a></li> -->
                                        </ul>
                                    </div>  
                                </div>
                            </nav>
                        </div>
                    </div>
                </header>
            </div> 
        </div>
    </div>
</template>
  
<script>
    import { ref } from 'vue';
    export default {
        name: 'HeaderComponent',
        setup() {
            let showMenu = ref(false);
            const toggleNav = () => (showMenu.value = !showMenu.value);
            return { showMenu, toggleNav };
        },
    }
</script>

<style>

    .header {
        width: 100%;
        background-color: white;
        /* height: 80px; */
        display: flex;
        padding-left: calc((100% - 1170px) / 2);
        padding-right: calc((100% - 1170px) / 2);
        justify-content: space-between;
        align-content: center;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
    }
    @media screen and (max-width: 1024px){
        .header {
            width: 100%;
            display: block;
        }

        .header .container {
            width: 100%;
            padding-right: 0;
            padding-left: 0;
            margin-right: 0;
            margin-left: 0;
        }
    }

    .logo {
        background: url('@/assets/header/logo_sonc.png') no-repeat;
    }
    
    .b-top-nav{
        background: #042d46;
        height: 38px;
        margin-bottom: 23px;
        display: none;
    }

    .b-top-nav .container{
    padding: 0px;
    }



    .b-navbar-top ul{
    list-style: none;
    text-align: right;
    margin-bottom: 0px;
    padding: 0px;
    }

    .b-navbar-top li{
    display: inline-block;
    margin: 0px 17px;
    position: relative;
    line-height: 35px;
    }

    .b-navbar-top li:after{
    content: "/";
    color: #464646;
    right: -21px;
    top: 1px;
    position: absolute;
    }

    .b-navbar-top li:last-child:after{
    content: "";
    }

    .b-navbar-top li:last-child a{
    color: #009ddc; 
    }
    .b-navbar-top li:last-child{
    margin-right: 0; 
    }

    .b-navbar-top li a{
    color: #fff;
    font-size: 10px;
    text-decoration: none;
    }

    .b-navbar-top-parent .navbar-toggle{ 
    margin-top: 0px;
    margin-bottom: 0px;
    } 

    header{
        /*border-bottom: 1px #82a0b1 solid;*/
        /* padding-bottom: 18px; */
        width: 100%;
        min-height: 66px;
    }

    .logo{
        width: 160px;
        height: 67px;
        position: relative;
        text-indent: -9999px;
        z-index: 14;
        background-size: 100%!important;
        margin: 16px auto;
    } 


    .b-navbar-top ul{
    list-style: none;
    text-align: right;
    margin-bottom: 0px;
    padding: 0px;
    }

    .b-navbar-top li{
    display: inline-block;
    margin: 0px 11px;
    position: relative;
    line-height: 31px;
    }

    .b-navbar-top li:after{
    content: ">";
    color: #FFFFFF;
    right: -11px;
    top: 2px;
    font-size: 9px;
    position: absolute;
    }


    li.menu-item-has-children > a{
    /* text-transform: uppercase;  */
    }
    
    li.menu-item-has-children > a:focus, a:hover{ 
    text-decoration: none;
    }
    


    .b-navbar-top li:nth-child(4){
    margin-right: 24px;
    }

    .b-navbar-top li:last-child:after{
    content: "";
    }

    .b-navbar-top li:last-child a{
    color: #009ddc; 
    }
    .b-navbar-top li:last-child{ 
    margin-right: 0px;
    margin-left: 0px;
    }

    .b-navbar-top li a{
    color: #fff;
    font-size: 10px;
    text-decoration: none;
    }

    .b-navbar-top li a:hover{
    text-decoration: underline;
    }
    /*
    .b-navbar-top li a.firt_el:after{
    position: absolute;
    content: ">";
    right: -11px;
    top: -8px;
    color: #fff;
    font-size: 8px;
    text-decoration: none;
    }
    .b-navbar-top li a:first-child{
    position: relative;
    }*/


    .b-navbar-main-parent ul.b-navbar-main__ul{
        list-style: none;
        margin-bottom: 0;
        padding-left: 0;
        /* padding-top: 15px; */
        min-height: 43px;
        text-align: right;
        padding-right: 0px;
    }
    
    .b-navbar-main-parent li{
        display: inline-block;
        /* position: relative; */
        text-align: left;
        vertical-align: top;
        padding-bottom: 9px;
    }
    
    .b-navbar-main-parent li.menu-item-has-children > a{ 
        padding-right: 35px;
        margin-right: 20px;
    }
    
    .b-navbar-main-parent li#menu-item-491 a {
        margin-right: 0px;
        font-size: 22px;
        cursor: default;
    }
    .b-navbar-main-parent li#menu-item-491 a:hover {
        color: #0A547F;
        opacity: 1;
    } 

    .menu-item-491{
        margin-top: -5px;
    }

    .b-navbar-main-parent li:first-child > a:before{ 
        /* content: "";
        position: absolute;
        left: 1px;
        bottom: -4px;
        background: #fc6260;
        width: 27px;
        height: 3px; */
    }

    /* .b-navbar-main-parent ul li.menu-item-has-children > a:after{
        content: "";
        position: absolute;
        right: 7px;
        top: 8px;
        background: url('@/assets/home/sprite_home.png') no-repeat 0px -447px;
        width: 12px;
        height: 8px;
    }
    .b-navbar-main-parent ul li:not(.menu-item-has-children) > a:after{
    content: ".";
        position: absolute;
        right: -12px;
        color: #fff;
        font-size: 15px;
        top: -4px;
        width: 2px;
        height: 2px;
    } */

    .b-navbar-main-parent ul li:last-child > a:after{
    display: none;
    }

    .b-navbar-main-parent ul li a{
        /* color: #034F7B;
        font-size: 18px;
        font-weight: 600;
        position: relative;
        padding-right: 10px;
        margin-right: 29px; */
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #707070;
    }

    .b-navbar-main-parent ul  li a{ 
        /* text-transform: uppercase; */
    }
    .b-navbar-main-parent ul  li ul li a{ 
        text-transform: inherit;

    }

    .b-navbar-main-parent ul  li ul li a:before, .b-navbar-main-parent ul  li ul li a:after {
    display: none;
    } 

    .b-navbar-main-parent ul li a:hover{
    text-decoration: none; 
        color: #FB3F3C;
    }


    .b-navbar-main-parent li a span{ 
    font-size: 18px;
    line-height: 11px;
    }

    body.category-careers .b-quote-block{
    margin-top: 25px;
    margin-right: 25px;
    }

    .b-navbar-main{
    padding: 0px;
    }

    .b-navbar-main-parent{ 
        border-radius: 0px;
        width: 100%;
        padding-top: 6px;
        min-height: 46px;
        margin-bottom: 0px;
        margin-top: -64px;
    }

    @media screen and (max-width: 1024px){
        .b-navbar-main-parent{ 
            border-radius: 0px;
            width: 100%;
            padding-top: 0;
            min-height: auto;
            margin-bottom: 0px;
            margin-top: 0px;
            vertical-align: middle;
            display: inline;
        }
    }

    .b-navbar-main2-parent{
        padding-top: 17px;
        height: 50px;
        overflow: hidden;
    }

    .b-navbar-main2-parent ul{
    list-style: none;
        margin-bottom: 0;
        padding-left: 20px;
        text-align: right;
    }

    .b-navbar-main2-parent li{
    display: inline-block;
    position: relative;
    margin-left: 23px;
    text-transform: uppercase;
    }


    .b-navbar-main2-parent li:before{
    content: ".";
    position: absolute;
    right: -12px;
    color: #fff;
    font-size: 15px;
    top: -4px;
    width: 2px;
    height: 2px;
    }
    .b-navbar-main2-parent li:last-child:before{
    content: "";
    position: absolute; 
    }
    
    .b-title-menu-top{
    font-size: 22px;
    font-weight: 600;
    color: #fffefe;
    width: 100%;
    clear: both;
    float: left;
    margin-bottom: 22px;
    }

    .b-navbar-main ul li:hover ul {
    opacity: 1;
    visibility: visible;
    }

    #menu-item-32, #menu-item-33 ul{
    line-height: 35px;
    }
    #menu-item-32 ul{
    padding-bottom: 2px;
    padding-top: 10px;
    }

    .b-navbar-main ul li ul {
        /* position: absolute;
        right: 0px;
        padding-top: 3%;
        visibility: hidden;
        opacity: 0;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        top: 54px; 
        padding-bottom: 2%;
        padding-left: 3%;
        z-index: 11;
        background: #FAFAFA;
        border-bottom: 6px #fb3f3c solid; */
    }
    

    .b-navbar-main li ul li:first-child:before{
    background: none;
    }
    .b-navbar-main li ul li a:hover{
    color: #fb3f3c; 
    }
    .b-navbar-main li ul li a{ 
    font-size: 22px;
    }

    .b-navbar-main li ul li:after{
    background: none; 
    } 

    .b-navbar-main ul li.our-devision-menu ul { 
    left: -407px; 
    } 

    .b-navbar-main ul li#menu-item-33 ul { 
    left: -581px; 
    } 

    /* saf3+, chrome1+ */
    @media screen and (-webkit-min-device-pixel-ratio:0) {
    .b-navbar-main ul li#menu-item-32 ul { 
    left: -573px;
    }
    }


    .b-navbar-main ul li#menu-item-32 ul { 
    left: -574px;
    }

    .b-navbar-main ul li#menu-item-32 ul li{ 
    display: inline-block;
    margin-right: 0px;
    line-height: 29px;
    margin-bottom: 14px;
    }
    .b-navbar-main ul li#menu-item-32 ul li a { 
    font-size: 18px;
    }

    .b-navbar-main ul li#menu-item-33 ul li {
    display: inline-block;
    width: 268px;
    margin-right: 0px;
    padding-right: 0px;
    vertical-align: top;
    }

    .b-navbar-main ul li#menu-item-33 ul li a {  
    font-size: 16px!important ;
    font-weight: normal;
    }

    .b-navbar-main ul li#menu-item-33 ul li:last-child{
    margin-left: 0;
    }

        
    .b-navbar-main ul li ul li:last-child{
    margin-left: 0;
    }

        


    .b-navbar-main2-parent li:first-child{
    margin-left: 0; 
    }
    .b-navbar-main2-parent li a{
        color: #fff;
        font-size: 18px;
        font-weight: 600;
    }
    .b-navbar-main2-parent li a:hover{ 
    text-decoration: none; 
    color: #C3C3C3;
    }
    .is_mobile {
        display: none;
    }
    .menu_mobile {
        margin:10px;
        display: block;
        z-index: 11111;
        min-width: 160px;
    }
    .menu-button {
        background-color: transparent;
        border: none;
    }
    .menu-items {
        background-color: white;
        padding: 10px;
        display: block;
        min-width: 160px;
    }
    .menu-items li {
        display: block;
        color: #034F7B;
        font-size: 12px;
        font-weight: 600;
        position: relative;
        margin-bottom: 5px;
        border-bottom: 1px solid #C3C3C3;
    }
    @media (max-width: 1024px) {
        .is_mobile {
            display: block;
        }

        .b-navbar-main-parent li {
            margin-top: 12px;
        }
    }
</style>