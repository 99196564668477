<template>
    <div class="company">
        <div class="item-company" v-for="(company, index) in companys" :key="index" :class="company.id" >
            <div class="img-company">
                <img :src="company.img" :alt="company.name">
            </div>
            <div class="company-text">
                {{ company.text }}
            </div>
            <div class="company-button">
                <a target="_blank" :href="company.url">Visit</a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'MyCompany',
        data() {
            return {
                companys: [
                    {
                        id: 'pipe',
                        name: 'Sonco Pipe & Tube',
                        text: 'The leading galvanized steel tubing distributor in the Northeast, Midwest, and Great Lakes regions since 1976.',
                        img: require('@/assets/home/company/sonco-pipe-tube.png'),
                        imgBackground: require('@/assets/home/company/sonco-pipe-tube-background.png'),
                        url: 'https://soncotube.com/'
                    },
                    {
                        id: 'perimeter',
                        name: 'Sonco Perimeter Security',
                        text: 'Crowd control and perimeter security expert, supplying temp fence, steel barricades and more for over 45 years.',
                        img: require('@/assets/home/company/Sonco_safety_mkt.png'),
                        imgBackground: require('@/assets/home/company/sonco-perimeter-security-background.png'),
                        url: 'https://www.soncocrowdcontrol.com/'
                    },
                    {
                        id: 'traffic',
                        name: '95 Traffic Safety Supply',
                        text: 'The leading experts in traffic safety, PPE and construction supplies, we serve the DC, Maryland and Virginia areas with over 100 years of combined experience.',
                        img: require('@/assets/home/company/95-traffic-safety-supply.png'),
                        imgBackground: require('@/assets/home/company/95-traffic-safety-supply-background.png'),
                        url: 'https://95tss.com/'
                    },
                    {
                        id: 'signzilla',
                        name: 'Signzilla',
                        text: 'We specialize in high-quality custom fence screen and outdoor signage with a streamlined pricing and ordering process.',
                        img: require('@/assets/home/company/signzilla.png'),
                        imgBackground: require('@/assets/home/company/signzilla-background.png'),
                        url: 'https://signzilla.com/'
                    }
                ]
            }
        },
    }
</script>


<style>

    .company {
        display: flex;
        max-width: 1152px;
        margin: 0 auto;
        flex-wrap: wrap;
        gap: 24px;
        margin-bottom: 32px;
    }

    .company .item-company {
        width: 564px;
        height: 300px;
        mix-blend-mode: multiply;
        background-color: #9B4F5B;
    }

    .company .item-company.pipe {
        background-image: url('@/assets/home/company/sonco-pipe-tube-background-v2.png');
    }

    .company .item-company.perimeter {
        background-image: url('@/assets/home/company/sonco-perimeter-security-background-v2.png');
    }

    .company .item-company.traffic {
        background-image: url('@/assets/home/company/95-traffic-safety-supply-background-v2.png');
    }

    .company .item-company.signzilla {
        background-image: url('@/assets/home/company/signzilla-background-v2.png');
    }

    .company .item-company .img-company {
        margin-top: 52px;
        min-height: 69px;
    }

    .company .item-company .company-text {
        margin-top: 16px;
        max-width: 300px;
        margin: 16px auto;
        color: white;
        font-family: 'Arial';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        text-align: center;
    }

    /* .company .item-company .company-button {
    } */

    .company .item-company .company-button a {
        width: 158px;
        height: 41px;
        border: 1px solid #FCFCFC;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        display: flex;
        align-items: center;
        color: #FCFCFC;
        align-content: center;
        justify-content: center;
        margin: 0 auto;

    }

    @media screen and (max-width: 1153px) {
        .company .item-company {
            width: 48%;
        }
    }

    @media screen and (max-width: 767px) {
        .company .item-company {
            width: 100%;
            margin: 0 16px;
        }
    }

</style>