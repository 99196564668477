<template>
    <div class="banner">
        <div class="banner-logo">
            <img :src="banner" :alt="name">
        </div>
        <div class="banner-title">
            <h1>Welcome to Hartwell Companies</h1>
        </div>
        <div class="banner-sub-title">
            <h2>Formerly SONCO Worldwide</h2>
        </div>
        <div class="banner-sub-title">
            <h3>The umbrella under which accomplished, strategically-aligned businesses grow.</h3>
        </div>
        <div class="banner-sub-title">
            <h4><a target="_blank" href="https://hartwellcompanies.com/">Click here</a> to learn more about Hartwell and our family of businesses.</h4>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'MyBanner',
        data() {
            return {
                banner: require('@/assets/home/banner/logo-hartwell.png'),
                name: 'Hartwell'
            }
        },
    }
</script>


<style>
    @import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;500;700&display=swap');

    .banner {
        margin-bottom: 24px;
        background: #E4002B;
        mix-blend-mode: darken;
        background-image: url('@/assets/home/banner/banner-home.png');
        min-height: 431px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .banner .banner-title h1 {
        /* max-width: 987px; */
        font-family: 'Roboto Slab';
        font-style: normal;
        font-weight: 700;
        font-size: 64px;
        line-height: 84px;

        color: #FFFFFF;
    }

    .banner .banner-sub-title h2 {
        font-family: 'Roboto Slab';
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 42px;
        color: #FFFFFF;
    }

    .banner .banner-sub-title h3 {
        font-family: 'Arial';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 23px;
        color: #FFFFFF;
    }

    .banner .banner-sub-title h4, .banner .banner-sub-title h4 a {
        font-family: 'Roboto Slab';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        color: #FFFFFF;
    }

    @media screen and (max-width: 1153px) {
        .banner {
            background-size: cover;
            padding: 16px;
        }

        .banner .banner-title h1 {
            font-size: 48px;
            line-height: 63px;
        }

        .banner .banner-sub-title h2 {
            font-size: 24px;
            line-height: 32px;
        }

        .banner .banner-sub-title h3 {
            font-size: 16px;
            line-height: 18px;
        }
        .banner .banner-sub-title h4, .banner .banner-sub-title h4 a {
            font-size: 18px;
            line-height: 24px;
        }
    }

    @media screen and (max-width: 767px) {
    }

</style>