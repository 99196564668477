import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  // {
  //   path: '/contact-us',
  //   name: 'contact',
  //   component: () => import(/* webpackChunkName: "contact" */ '../views/ContactView.vue')
  // },
  {
    path: '/rentersclub',
    name: 'rentersclub',
    component: () => import(/* webpackChunkName: "rentersclub" */ '../views/RentersClubView.vue')
  },
  {
    path: '/careers',
    name: 'careers',
    component: () => import(/* webpackChunkName: "careers" */ '../views/CareersView.vue')
  },
  {
    path: '/terms-conditions',
    name: 'terms-conditions',
    component: () => import(/* webpackChunkName: "terms-conditions" */ '../views/TermsView.vue')
  },
  {
    path: '/sms-policy',
    name: 'sms-policy',
    component: () => import(/* webpackChunkName: "sms-policy" */ '../views/SmsPolicyView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
