<template>
	<metainfo>
		<template v-slot:title="{ content }">{{ content ? `${content} | Sonco World Wide` : `Sonco World Wide` }}</template>
	</metainfo>
	<div class="page">
		<HeaderComponent />
		<router-view/>
		<FooterComponent />
	</div>
</template>

<script>
	import HeaderComponent from '@/components/default/HeaderComponent.vue';
	import FooterComponent from '@/components/default/FooterComponent.vue';
	
	export default {
		name : 'App',
		components : {
			HeaderComponent,
			FooterComponent
		}
	}
</script>

<style>
	html, body { 
		height: 100%;
		width: 100%;
		margin: 0;
	}

	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
	}
</style>
