<template>
    <footer class="footer">
        <!-- <div class="row b-before-footer">
            <div class="col-md-12 col-sm-12">
                <div class="b-footer-info">
                    <span class="b-footer-info__tel">1-888-766-2618</span>
                    <span class="b-footer-info__email"><a href="/contact-us">Email Us</a></span> 
                </div>
            </div> 
        </div> -->
        <div class="row">
            <footer class="clearfix">
                <div class="b-footer-section padding0 col-sm-12 col-md-12"> 
                    <div class="logo-hartwell"><img src="@/assets/footer/hartwell-logo.png" alt="Hartwell"/></div>
                    <div class="b-foot-adress"><b>Headquarters</b></div>
                    <div class="b-foot-adress">9011 East Hampton Drive Capitol Heights, MD 20743</div> 
                    <div class="b-foot-adress"><b>|</b></div> 
                    <div class="b-foot-adress">(888) 766-2618</div> 
                    <div class="b-foot-adress"><b>|</b></div> 
                    
                    <div class="b-foot-copyright">Copyright 2023</div>
                    <div class="b-foot-adress"><b>|</b></div> 
                    <div class="b-foot-term"><a href="/terms-conditions">Terms &amp; Conditions</a></div>
                </div>
            </footer> 
        </div>
    </footer>
</template>

<script>
    export default {
        name: 'FooterComponent',
    }
</script>

<style>
    .footer {
        width: 100%;
        /* height: 200px; */
        color: white;
        display: flex;
        padding-left: calc((100% - 1170px) / 2);
        padding-right: calc((100% - 1170px) / 2);
        /* align-items: center; */
        flex-flow: column;
        text-align: left;
        background: #F5F5F5;
        border-top: 1px solid #FD1D35;
    }

    .logo-hartwell {
        /* margin: 45px 0; */
    }

    .b-before-footer{ 
        margin: 0;
        margin-bottom: 15px;
        background: #01263c;
        min-height: 68px;
        padding-top: 23px;
        color: #fff;
        width: 100%;
    }

    .b-footer-info__tel{
    font-size: 22px;
    font-weight: 300;
    margin-right: 54px;
    padding-left: 18px;
    line-height: 20px;
    }

    .b-footer-info__email a{
    font-size: 22px;
    font-weight: 300;
    color: #fff;
    line-height: 19px;
    background: url('@/assets/home/sprite_home.png') no-repeat 0px -97px;
    padding-left: 34px;
    padding-right: 38px;
    }


    .b-footer-info__careers a{
    font-size: 22px;
    font-weight: 300;
    color: #fff;
    line-height: 20px;
    background: url('@/assets/home/sprite_home.png') no-repeat 0px -138px;
    padding-left: 34px;
    }


    .b-before-footer__logo{
    width: 172px;
    height: 40px;
    float: right;
    margin-right: 1px;
    background: url('@/assets/home/sprite_home.png') no-repeat 0px -233px;
    margin-top: -11px;
    }

    .b-before-footer__logo a{
    width: 173px;
    height: 40px;
    display: block;
    margin-left: -14px; 
    }

    footer{
    min-height: 72px;
    }

    .b-footer-section{
        margin: 45px 0px;
        font-size: 13px;
        font-weight: 300;
        color: #000;
        display: flex;
        align-content: center;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .b-footer-section a{ 
    color: #000;
    }

    .b-foot-name{
    color:#009ddc;
    }


    .b-footer-section div{
    display: inline-block;
    margin-right: 17px;
    }

    .b-foot-adress{

    }

    .b-foot-tel{

    }

    .b-foot-contus{

    }

    .b-foot-copyright{

    }

    .b-foot-term{

    }

    .b-foot-design{

    }

    @media (max-width: 1024px) {
        .footer .row {
            max-width:100%;
            margin: 0;
        }

        .logo-hartwell {
            width: 100%;
            display: flex !important;
            justify-content: center;
            margin-bottom: 20px;
        }
    }

</style>